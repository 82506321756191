import React from "react"

const BookYear = ({ data }) => {
  const { year, quartals } = data

  return (
    <div>
      <h3 style={{ textDecoration: "underline" }}>{year}</h3>
      {quartals.map(({ quartal, books }) => (
        <div>
          <h4 style={{marginTop: "2.75rem"}}>Quartal {quartal}</h4>
          <ul>
            {books.map(({ title, author, rating }, i) => (
              <li key={i}>
                <>{title}</> <br />
                <small>
                  {author}
                  {rating !== null && <> - ({rating}/5)</>}
                </small>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div
        style={{
          height: "1px",
          width: "100%",
          border: "1px solid #f2f2f2",
          borderRadius: "8px",
        }}
      />
    </div>
  )
}

export default BookYear
