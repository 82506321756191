import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import BookYear from "../components/bookYear"

const BooksPage = props => {
  const data = useStaticQuery(graphql`
    query {
      allBooksYaml {
        edges {
          node {
            year
            quartals {
              quartal
              books {
                title
                author
                rating
              }
            }
          }
        }
      }
    }
  `)

  const siteTitle = "Books"

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Books" keywords={[`books`]} />
      <p>
        During the Covid-19 pandemic I developed an great interest in reading
        books. So here's a list of the ones I've read and plan to read since
        2021. From there on I try to read at least one to two books every month.
        Mostly I read about scientific topics like computer science, artificial
        intelligence or technology in general. The number in brackets is my
        personal rating from 1 (bad) to 5 (good).
      </p>
      <p>Feel free to share your book recommendations with me!</p>
      {data.allBooksYaml.edges.map(edge => (
        <BookYear data={edge.node} />
      ))}
      <Link to="/">
        <Button marginTop="35px">Home</Button>
      </Link>
    </Layout>
  )
}

export default BooksPage
